<template>
  <div>
    <AppSpinnerBig :loading="loadingOnfido"></AppSpinnerBig>

    <AppModal v-model="showModal" :title="$t('AccountOnfido.Title').value" :class="animationClass">
      <template #header>
        <AppModalHeader>
          {{ $t('AccountOnfido.Title').value }}
          <template #right>
            <AppBackButton
              analytics-name="account-onfido-back"
              @click="showLeaveConfirmationDialog = true"
            />
          </template>
        </AppModalHeader>
      </template>

      <OnfidoSelectDocument @select="select"></OnfidoSelectDocument>
    </AppModal>

    <Onfido @init="init" @loaded="loaded" @successful="done" @close="close"> </Onfido>

    <AppDialogConfirmation
      v-model="showLeaveConfirmationDialog"
      analytics-name="send-verify-identity-onfido-leave-confirmation"
      :title="$t('PageSendMoneyVerifyIdentityCancel.Title').value"
      :cancel-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonYes').value"
      :confirm-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonNo').value"
      @cancel="back"
      @confirm="showLeaveConfirmationDialog = false"
    >
      <p>{{ $t('PageSendMoneyVerifyIdentityCancel.Message').value }}</p>
    </AppDialogConfirmation>
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { ref } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'
import OnfidoSelectDocument from '@galileo/components/Views/Identification/OnfidoSelectDocument'
import Onfido from '@galileo/components/Views/Identification/Onfido'

import {
  useTransition,
  useAlert,
  AppBackButton,
  AppModal,
  AppModalHeader,
  AppSpinnerBig,
  AppDialogConfirmation,
} from '@oen.web.vue2/ui'

export default {
  name: 'AccountOnfido',
  components: {
    AppBackButton,
    AppModal,
    AppModalHeader,
    AppSpinnerBig,
    OnfidoSelectDocument,
    Onfido,
    AppDialogConfirmation,
  },

  setup() {
    const router = useRouter()
    const { add: addAlert } = useAlert()
    const { $t } = useI18nStore()

    const showModal = ref(true)
    const showLeaveConfirmationDialog = ref(false)
    const loadingOnfido = ref(false)
    const animationClass = useTransition('blur', loadingOnfido)

    let onfidoCallback
    const init = (ev) => {
      onfidoCallback = ev
    }

    const loaded = () => {
      loadingOnfido.value = false
    }

    const select = (document) => {
      loadingOnfido.value = true
      onfidoCallback.selectDocument(document)
    }

    const done = () => {
      addAlert('Identification successful')
      router.push('/account')
    }

    const back = () => {
      // a bit complicated since eventually Onfido cluttered the history but didn't clean it up
      router.goBackSteps(1)
    }

    const close = () => {
      router.replace({ name: 'SendMoneySummary' })
    }

    return {
      showModal,
      init,
      loaded,
      select,
      done,
      back,
      loadingOnfido,
      animationClass,
      $t,
      showLeaveConfirmationDialog,
      close,
    }
  },
}
</script>

<style scoped></style>
